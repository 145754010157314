<template>
    <div>
        <template v-if="loading">
            <div class="card card-custom card-stretch">
                <!--begin::Header-->
                <div class="card-header h-auto border-0">
                    <!--begin::Title-->
                    <div class="card-title py-5">
                        <h3 class="card-label">
                            <span class="d-block text-dark font-weight-bolder">Data Transfer</span>
                            <span class="d-block text-muted mt-2 font-size-sm">Sedang memuat data deplesi..</span>
                        </h3>
                    </div>
                    <!--end::Title-->
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body">
                    <ContentLoader></ContentLoader>
                </div>
                <!--end::Body-->
            </div>
        </template>
        <template v-else>
            <BarChart
                title="Transfer"
                deskripsi="Data Transfer Perbulan yang telah diinput"
                :setSeries="item.series"
                :categories="item.categories"
            ></BarChart>
        </template>
    </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'
import BarChart from "@/view/content/widgets/chart/Bar.vue"

export default {
    props: {
        sdata:{
            type: [Object, Array],
            default: null
        },
    },
    components: {
        ContentLoader,
        BarChart
    },
    data() {
        return {
            loading: true,
            item: null
        }
    },
    methods: {
        getData() {
            this.item = {
                series: [{
                    name: "Transfer",
                    data: [100, 300, 322, 332]
                }],
                categories: ['Jan', 'Feb', 'Mar', 'Apr']
            }

            setTimeout(() => {
                this.loading = false            
            }, 500);
        }
    },
    mounted() {
        this.getData()
    },
}
</script>