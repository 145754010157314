<template>
    <div id="statistik-user-chart" class="row">
        <template v-for="(item, index) in items">
            <div v-if="item.component" :key="index" :class="item.raw">
                <component :sdata="sdata" v-bind:is="item.component"></component>
            </div>
        </template>
    </div>
</template>

<script>
import ChickinData from "@/view/pages/statistik/display/data/nasional/populasi/Chickin.vue"
import DeplesiData from "@/view/pages/statistik/display/data/nasional/populasi/Deplesi.vue"
import TransferData from "@/view/pages/statistik/display/data/nasional/populasi/Transfer.vue"
import ProduksiEggData from "@/view/pages/statistik/display/data/nasional/populasi/ProduksiEgg.vue"
import AfkirData from "@/view/pages/statistik/display/data/nasional/populasi/Afkir.vue"
import SettingHEData from "@/view/pages/statistik/display/data/nasional/produksi/SettingHE.vue"
import PullchickData from "@/view/pages/statistik/display/data/nasional/produksi/Pullchick.vue"
import SendDocData from "@/view/pages/statistik/display/data/nasional/distribusi/SendDoc.vue"
import AfkirDiniData from "@/view/pages/statistik/display/data/project/AfkirDini.vue"
import CuttingHEData from "@/view/pages/statistik/display/data/project/CuttingHE.vue"

export default {
    props: {
        sdata:{
            type: [Object, Array],
            default: null
        },
    },
    components: {
        ChickinData,
        DeplesiData,
        TransferData,
        ProduksiEggData,
        AfkirData,
        SettingHEData,
        PullchickData,
        SendDocData,
        AfkirDiniData,
        CuttingHEData,
    },
    data() {
        return {
            items: [
                {
                    raw: 'col-12 col-sm-6',
                    component: 'ChickinData'
                }, {
                    raw: 'col-12 col-sm-6',
                    component: 'DeplesiData'
                }, {
                    raw: 'col-12 col-sm-6',
                    component: 'TransferData'
                }, {
                    raw: 'col-12 col-sm-6',
                    component: 'ProduksiEggData'
                }, {
                    raw: 'col-12 col-sm-6',
                    component: 'AfkirData'
                }, {
                    raw: 'col-12 col-sm-6',
                    component: 'SettingHEData'
                }, {
                    raw: 'col-12 col-sm-6',
                    component: 'PullchickData'
                }, {
                    raw: 'col-12 col-sm-6',
                    component: 'SendDocData'
                }, {
                    raw: 'col-12 col-sm-6',
                    component: 'AfkirDiniData'
                }, {
                    raw: 'col-12 col-sm-6',
                    component: 'CuttingHEData'
                }
            ]
        }
    },
}
</script>