<template>
  <div id="report-weekly-dinamis-table" data-app>
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">
            {{ sdata.title | title }}
          </h3>
        </div>
      </div>

      <!--begin::Table-->
        <div class="card-body pt-0 pb-0">
          <v-data-table
            :headers="fields"
            :items="items"
            hide-default-footer
            class="elevation-1"
            disable-sort
            :loading="isBusy"
            loading-text="Loading... Please wait"
          >
          </v-data-table>
        </div>
      <!--end::Table-->  
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ReportService from "@/core/services/api/nasional/report.service";
import ErrorService from "@/core/services/error.service";

export default {
  name: "report-weekly-dinamis-table",
  props: {
    sdata:{
        type: [Object, Array],
        default: null
    },
  },
  data() {
    return {
      isBusy: true,
      fields: [{ value: "umur", text: "Umur" }, { value: "weekly", text: "Weekly" }, {value: "cum", text: "Cum"}],
      items: []
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    resetTable() {
      this.items = []
      this.fields = [{ value: "umur", text: "Umur" }, { value: "weekly", text: "Weekly" }, {value: "cum", text: "Cum"} ]
    },
    getData () {
      let funcData
      const dataDispaly = ['produksi-he', 'produksi-telur', 'populasi-produktif', 'produksi-doc', 'hatchebility', 'fertility', 'hatchebility-rerata', 'fertility-rerata']
      
      if(!dataDispaly.includes(this.sdata.kategori)) return

      if(this.sdata.kategori == 'produksi-he') funcData = ReportService.getTableProduksiHE({ populasi: this.sdata.populasi })
      if(this.sdata.kategori == 'produksi-telur') funcData = ReportService.getTableProduksiTelur({ populasi: this.sdata.populasi })
      if(this.sdata.kategori == 'populasi-produktif') funcData = ReportService.getTableStandingPopulasi({ populasi: this.sdata.populasi })
      if(this.sdata.kategori == 'produksi-doc') funcData = ReportService.getTableProduksiDoc({ setting: this.sdata.setting_he })
      if(this.sdata.kategori == 'hatchebility') funcData = ReportService.getTableHatchebility({ setting: this.sdata.setting_he })
      if(this.sdata.kategori == 'fertility') funcData = ReportService.getTableFertility({ setting: this.sdata.setting_he })
      
      funcData.then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.resetTable()
                this.isBusy = false
                return;
            }
            this.items = res.data.data.data
            this.fields = res.data.data.fields
            this.isBusy = false
        })
        .catch((err) => {
          this.resetTable()
          this.isBusy = false
          return ErrorService.status(err)
        })
    },
  },
  mounted() {
    this.getData()
  },
};
</script>

<style lang="scss">
#report-weekly-dinamis-table {
  .v-data-table {
    .v-data-table__wrapper {
      table {
        tbody {
          tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
            background: none !important;
          } 
        }
      }
    
      .v-data-table__mobile-row {
        min-height: 28px !important;
      }
    }
  }
}
</style>