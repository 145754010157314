<template>
    <div id="statistik-user-chart" class="row">
        <template v-for="(item, index) in items">
            <div v-if="item.component" :key="index" :class="item.raw">
                <component :sdata="sdata" v-bind:is="item.component"></component>
            </div>
        </template>
    </div>
</template>

<script>
import TableDimanis from "@/view/pages/nasional/report/_weekly/_table/TableDinamis.vue"

export default {
    props: {
        sdata:{
            type: [Object, Array],
            default: null
        },
    },
    components: {
        TableDimanis
    },
    data() {
        return {
            items: [
                {
                    raw: 'col-12',
                    component: 'TableDimanis'
                }, 
            ]
        }
    },
}
</script>